import React, { useState } from "react";

import { BSBtn, BSCard } from "traec-react/utils/bootstrap";
import { ProjectPermission } from "../utils/permissions";

import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import DataTable from "react-data-table-component";
import Im from "immutable";
import { ActionIcon } from "./utils";
import { Hide } from "../../utils";

const updateAuthGroup = ({ projectId, authGroup, data, hideModal, trigger }) => {
  let authGroupId = authGroup?.get("uid");
  let url = `/api/project/${projectId}/authgroup/` + (authGroupId ? `${authGroupId}/` : "");
  let method = authGroupId ? "PATCH" : "POST";
  doFetch(url, method, data).then(() => {
    if (trigger) trigger();
    if (hideModal) hideModal();
  });
};

function NewAuthGroupModal(props) {
  let { hideModal } = useModal();
  let { authGroup } = props;
  return (
    <Modal title={authGroup ? "Edit Auth Group" : "Create new Auth Group"}>
      <AuthGroupForm {...props} hideModal={hideModal} />
    </Modal>
  );
}

const getColumns = (props) => [
  {
    name: "Name",
    sortable: true,
    selector: (item) => item.get("name"),
    format: (item) => item.get("name"),
    width: "90%",
  },
  {
    name: "",
    right: true,
    selector: (item) => "",
    format: (item) => (
      <>
        <ActionIcon
          iconName="pencil"
          onClick={() => props.setModal(<NewAuthGroupModal {...props} authGroup={item} />)}
        />
      </>
    ),
    width: "10%",
  },
];

export default function AuthGroupList({ projectId }) {
  let { setModal, hideModal } = useModal();
  let { data: authGroups, trigger } = useApi("/api/project/{projectId}/authgroup/", { projectId });

  let _props = { projectId, setModal, hideModal, trigger };
  return (
    <div className="row">
      <BSCard
        widthOffset="col-sm-12"
        title="Authority Groups"
        button={
          <ProjectPermission projectId={projectId} requiresAdmin={true}>
            <BSBtn onClick={() => setModal(<NewAuthGroupModal {..._props} />)} text="Add an Authority Group" />
          </ProjectPermission>
        }
        body={<DataTable columns={getColumns(_props)} data={authGroups?.toArray()} striped={true} dense={true} />}
      />
    </div>
  );
}

const operations = ["CREATE", "READ", "UPDATE", "DELETE"];

const viewObjectTypes = {
  TRACKER_REF: "Work Packages",
  PROJECT_MEMBER: "Project Users",
  PROJECT_REPORT: "Project Dashboard",
  TRACKER_REF_SCORE_VALUE: "Project Reporting",
};

function ActionsTableRows({ actions, setActions }) {
  let rows = [];
  let counter = 0;
  for (let [objKey, name] of Object.entries(viewObjectTypes)) {
    let colCells = operations.map((operation, i) => {
      let action = `${operation}_${objKey}`;
      let isChecked = actions.has(action);
      return (
        <td key={counter++} className="text-center" style={{ backgroundColor: isChecked ? "#99EB99" : "#EB9999" }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setActions(isChecked ? actions.delete(action) : actions.add(action))}
          />
        </td>
      );
    });
    // Add the row header
    colCells.unshift(
      <th key={counter++} scope="row">
        {name}
      </th>
    );
    // Add this to the rows
    rows.push(<tr key={counter++}>{colCells}</tr>);
  }
  return rows;
}

function ActionsTable(props) {
  let colHeadings = operations.map((o, i) => (
    <th key={i} scope="col" width="15%" className="text-center">
      {o}
    </th>
  ));
  colHeadings.unshift(<th key={colHeadings.length} scope="col" />);
  return (
    <div className="form-group row mb-0">
      <div className="col-sm-12">
        <table className="table table-sm">
          <thead>
            <tr>{colHeadings}</tr>
          </thead>
          <tbody>
            <ActionsTableRows {...props} />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function AuthGroupForm(props) {
  let { authGroup } = props;
  console.log("AuthGroupForm", authGroup?.toJS());
  let [name, setName] = useState(authGroup?.get("name") || "");
  let [isAdmin, setIsAdmin] = useState(authGroup?.get("is_admin") || false);
  let [actions, setActions] = useState(Im.Set(authGroup?.getIn(["policy_json", "actions"]) || Im.List()));
  return (
    <div className="container">
      <form>
        <div className="form-group row mb-1">
          <div className="col-sm-11">
            <input
              type="text"
              name="name"
              className="form-control-plaintext"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a name for this Role"
            />
          </div>
          <div className="form-check col-sm-1">
            <input
              className="form-check-input"
              type="checkbox"
              id="adminCheck"
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
            />
            <label className="form-check-label" htmlFor="adminCheck">
              Admin?
            </label>
          </div>
        </div>
        <Hide when={isAdmin}>
          <ActionsTable actions={actions} setActions={setActions} />
        </Hide>

        <button
          className="btn btn-sm btn-primary float-right"
          onClick={(e) => {
            e.preventDefault();
            updateAuthGroup({
              ...props,
              data: {
                name,
                is_admin: isAdmin,
                policy_json: { actions },
              },
            });
          }}
        >
          Save
        </button>
        <div style={{ clear: "both" }} />
      </form>
    </div>
  );
}
