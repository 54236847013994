import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors";
import UploadFileDragDrop from "AppSrc/utils/dragDropUpload";
import { doFetch } from "storybook-dashboard/utils/fetching";
import Im from "immutable";

function wait(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log("Done waiting");
      resolve(ms);
    }, ms);
  });
}

const postFile = ({ file, fileList, setPending, onChangeHandler, header }) => {
  setPending ? setPending(true) : null;

  let formData = new FormData();
  formData.append("file", file);

  doFetch("/api/store/object/", "POST", formData).then((data) => {
    console.log("postFile returned data from api", data);
    setPending ? setPending(false) : null;
    let newFileList = Im.fromJS(fileList || Im.List())
      .push({
        filename: file.name,
        url: data.url,
      })
      ?.toJS();
    onChangeHandler({ target: { name: header, value: JSON.stringify(newFileList) } });
  });
};

const droppedFileHandler = ({ files, fileList, setPending, onChangeHandler, header }) => {
  console.log("We are in the droppedFileHandler", files);
  for (let file of files) {
    console.log("droppedFileHandler posting file", file);
    postFile({ file, fileList, setPending, onChangeHandler, header });
  }
};

const deleteFile = ({ fileList, index, header, onChangeHandler }) => {
  let newFileList = Im.fromJS(fileList || Im.List())
    .delete(index)
    ?.toJS();
  onChangeHandler({ target: { name: header, value: JSON.stringify(newFileList) } });
};

export function FileList({ fileList, header, onChangeHandler, edit }) {
  console.log("Rendering files", fileList);
  let listing = (fileList || Im.List()).map((file, i) => {
    let { filename, url } = file;
    console.log("whatisEdit", edit);
    return (
      <li key={i}>
        <a href={url}>{filename}</a>
        {edit || typeof edit == "undefined" ? (
          <span
            style={{ cursor: "pointer" }}
            className="ml-2 bg-danger rounded-pill px-2 text-white"
            onClick={() => deleteFile({ fileList, header, index: i, onChangeHandler })}
          >
            Remove
          </span>
        ) : null}
      </li>
    );
  });
  return <ul style={{ listStyle: "none" }}>{listing}</ul>;
}

export const FormCellUpload = (props) => {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let error = errors.get(header);
  let fileList = JSON.parse(values.get(header) || null); // This is JSON like: [{"filename": "file1", "url": "https://s3.procedural.build/track/43/35/d3/fdsf"}]

  let [pending, setPending] = useState(false);

  return (
    <td className="">
      <ErrorBoundary>
        <FileList fileList={fileList} header={header} onChangeHandler={onChangeHandler} />
      </ErrorBoundary>
      {pending ? (
        <p className="text-center">Uploading...</p>
      ) : (
        <UploadFileDragDrop
          header={header}
          value={""}
          error={error}
          onChangeHandler={onChangeHandler}
          droppedFileHandler={(files) => droppedFileHandler({ files, fileList, setPending, onChangeHandler, header })}
        />
      )}
    </td>
  );
};
