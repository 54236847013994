import React from "react";
import Octicon from "react-octicon";

export const formEventToData = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  let data = Object.fromEntries(formData.entries());
  return data;
};

export function ActionIcon({ iconName, onClick, addClass }) {
  return (
    <span style={{ cursor: "pointer" }} onClick={onClick} className={addClass || ""}>
      <Octicon name={iconName} />
    </span>
  );
}
