import React from "react";

import { BSBtn, BSCard } from "traec-react/utils/bootstrap";
import { ProjectPermission } from "../utils/permissions";
import { useProjectPermissions } from "../utils/hooks";

import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import DataTable from "react-data-table-component";
import { ActionIcon, formEventToData } from "./utils";
import { Hide } from "AppSrc/utils";
import Im from "immutable";

const strToBool = (s) => (s === "true" ? true : s === "false" || !s ? false : s);

const sendProjectInvite = (e, { projectId, hideModal, triggerMembers, triggerInvites }) => {
  let data = formEventToData(e);

  let booleanKeys = Im.Set(["meta_json__auto_accept_if_user_exists"]);

  let _data = Object.entries(data).reduce(
    (a, [key, value]) => (!value ? a : a.setIn(key.split("__"), booleanKeys.has(key) ? strToBool(value) : value)),
    Im.Map()
  );

  doFetch(`/api/project/${projectId}/invite/`, "POST", _data).then(() => {
    if (triggerMembers) triggerMembers();
    if (triggerInvites) triggerInvites();
    if (hideModal) hideModal();
  });
};

const deleteMember = ({ projectId, item, triggerMembers, triggerInvites }) => {
  let memberId = item.get("uid");
  doFetch(`/api/project/${projectId}/member/${memberId}/`, "DELETE").then(() => {
    if (triggerMembers) triggerMembers();
    if (triggerInvites) triggerInvites();
  });
};

function InviteNewMemberFormModal(props) {
  let { hideModal } = useModal();
  let { projectId } = props;
  let { data: disciplines } = useApi("/api/project/{projectId}/discipline/", { projectId });

  return (
    <Modal title="Invite new member">
      <form onSubmit={(e) => sendProjectInvite(e, { ...props, hideModal })}>
        <div className="row">
          <div className="col-sm-8">
            <div className="form-group">
              <label htmlFor="dName">Email</label>
              <input className="form-control form-control-sm" name="email" id="dName" defaultValue={""} />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="dApprover">Project discipline</label>
              <select name="project_discipline" className="form-control form-control-sm" defaultValue={""}>
                {(disciplines || Im.List()).unshift(Im.Map({ uid: "", name: "" })).map((i, k) => (
                  <option key={k} value={i.get("uid")}>
                    {i.get("name")}
                  </option>
                ))}
              </select>{" "}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              class="form-check-input"
              name="meta_json__auto_accept_if_user_exists"
              type="checkbox"
              value={true}
              id="autoAcceptInvite"
            />
            <label class="form-check-label" htmlFor="autoAcceptInvite">
              Auto-accept (if/when user exists)
            </label>
          </div>
        </div>
        <button className="btn btn-sm btn-primary" type="submit">
          Submit
        </button>
      </form>
    </Modal>
  );
}

const getColumns = (props) => [
  {
    name: "Name",
    sortable: true,
    selector: (item) => `${item.getIn(["user", "first_name"])} ${item.getIn(["user", "last_name"])}`,
    format: (item) => `${item.getIn(["user", "first_name"])} ${item.getIn(["user", "last_name"])}`,
    width: "30%",
  },
  {
    name: "Email",
    sortable: true,
    selector: (item) => item.getIn(["user", "email"]),
    format: (item) => item.getIn(["user", "email"]),
    width: "30%",
  },
  {
    name: "Role",
    sortable: true,
    selector: (item) => item.getIn(["project_discipline", "name"]),
    format: (item) => item.getIn(["project_discipline", "name"]),
    width: "30%",
  },
  {
    name: "",
    right: true,
    selector: (item) => "",
    format: (item) => (
      <Hide when={!props?.isAdmin}>
        <ActionIcon iconName="trashcan" onClick={() => deleteMember({ ...props, item })} />
      </Hide>
    ),
    width: "10%",
  },
];

export default function MemberList({ projectId }) {
  let { setModal } = useModal();
  let { isAdmin } = useProjectPermissions();
  let { trigger: triggerInvites } = useApi("/api/project/{projectId}/invite/", { projectId });
  let { data: members, trigger: triggerMembers } = useApi("/api/project/{projectId}/member/", { projectId });

  console.log("MemberList", members?.toJS());

  return (
    <div className="row">
      <BSCard
        widthOffset="col-sm-12"
        title="Members"
        button={
          <ProjectPermission projectId={projectId} requiresAdmin={true}>
            <BSBtn
              onClick={() => setModal(<InviteNewMemberFormModal {...{ projectId, triggerMembers, triggerInvites }} />)}
              text="Send Invite"
            />
          </ProjectPermission>
        }
        body={
          <DataTable
            columns={getColumns({ projectId, triggerMembers, triggerInvites, isAdmin })}
            data={members?.toArray()}
            striped={true}
            dense={true}
          />
        }
      />
    </div>
  );
}
