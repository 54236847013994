import Moment from "moment";
import Im from "immutable";
import { isRequiredOnFreq } from "./reportMetricRow";

export const reportingPeriodText = (item) => {
  if (!item) {
    return null;
  }
  return `${Moment(item.get("startDate")).format("Do MMM YY")} to ${Moment(item.get("endDate"))
    .add(-1, "days")
    .format("Do MMM YY")}`;
};

export const dateToText = (date, offsetDays = -1) => {
  if (!date) return null;
  return `${Moment(date).add(offsetDays, "days").format("Do MMM YY")}`;
};

export const getLatestValue = (values) => {
  if (!values) {
    return null;
  }
  let latestTime = null;
  let latestValue = null;

  for (let [valueId, value] of values) {
    if (!latestTime) {
      latestValue = value;
      latestTime = new Date(value.get("created"));
    } else if (new Date(value.get("created")) >= latestTime) {
      latestValue = value;
      latestTime = new Date(value.get("created"));
    }
  }
  return latestValue;
};

const getNotRequiredCategoryPaths = (path, nodesByPath, currentReportingPeriod) => {
  // Get the categories and see if they are due this period
  let categories = nodesByPath.toList().filter((i) => i.get("_type") == "tree" && i.get("_path").startsWith(path));

  // Get the paths of issues that are not due in this period
  let excludeCategoryPaths = Im.Set();
  if (categories.size && currentReportingPeriod) {
    let categoriesNotDueThisPeriod = categories.filter(
      (i) => !!isRequiredOnFreq(i.get("meta_json"), currentReportingPeriod)
    );
    excludeCategoryPaths = categoriesNotDueThisPeriod.map((i) => i.get("_path"));
  }
  return excludeCategoryPaths;
};

const nodesInDueCategories = (path, nodesByPath, currentReportingPeriod) => {
  let excludeCategoryPaths = getNotRequiredCategoryPaths(path, nodesByPath, currentReportingPeriod);

  // Filter out metrics that are under issues not due this period
  return excludeCategoryPaths.reduce(
    (acc, path) => acc.filter((i) => !i.get("_path")?.startsWith(path)),
    (nodesByPath || Im.Map()).toList()
  );
};

export const getChildMetricScores = (path, nodesByPath, metricScores, currentReportingPeriod) => {
  if (!nodesByPath || !metricScores) return Im.List();

  return nodesInDueCategories(path, nodesByPath, currentReportingPeriod).filter(
    (i) => i.get("_type") == "metricscore" && i.get("_path").startsWith(path)
  );
};

export const getChildDocumentsWithStatus = (path, nodesByPath, getChildren, currentReportingPeriod) => {
  if (!nodesByPath) return Im.List();

  return nodesInDueCategories(path, nodesByPath, currentReportingPeriod)
    .filter((node) => node.get("_type") == "document" && node.get("_path")?.startsWith(path))
    .map((documentNode, _path) => {
      let documentStatus = getChildren(documentNode)
        .filter((i) => i.get("_type") == "documentstatus")
        .first();
      return documentNode?.set("status", documentStatus);
    });
};

export const pathHasInputs = (path, inputsByPath) => {
  return ((inputsByPath || Im.Map()).get(path) || Im.List()).filter((i) => i != undefined).size > 0;
};

const getKeySet = (o) => {
  return Im.Set(Im.isImmutable(o) ? o.keys() : Object.keys(o));
};

const getValue = (o, key) => {
  return Im.isImmutable(o) ? o.get(key) : o[key];
};

/* Get the diff between Javascript objects i and j - but the values may be immutable
 */
export const hasDiff = (i, j, logPrefix = "") => {
  // Check if the props keys have changed
  let iKeys = getKeySet(i);
  let jKeys = getKeySet(j);

  const logger = logPrefix ? console.log : () => {};

  if (!iKeys.equals(jKeys)) {
    logger(`${logPrefix}:Keys have changed`, iKeys.toJS(), jKeys.toJS());
    return true;
  }

  // Check if immutable structures in the props have changed
  for (let key of iKeys) {
    let _i = getValue(i, key);
    let _j = getValue(j, key);
    if (Im.isImmutable(_i)) {
      if (_i && !_i.equals(_j)) {
        logger(`${logPrefix}:Immutable value has changed`, key, _i.toJS(), Im.isImmutable(_j) ? _j.toJS() : _j);
        return true;
      }
    } else {
      if (_i !== _j) {
        logger(`${logPrefix}:Plain JS value has changed`, key, _i, _j);
        return true;
      }
    }
  }

  return false;
};
