import React from "react";

import { BSCard } from "traec-react/utils/bootstrap";

import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import DataTable from "react-data-table-component";
import { ActionIcon } from "./utils";
import { useProjectPermissions } from "../utils/hooks";
import { Hide } from "../../utils";

const repsondInvite = ({ projectId, item, triggerInvites, triggerMembers, accept }) => {
  console.log("sendProjectInvite");
  let status = accept ? "accepted" : "rejected";
  let inviteId = item.get("uid");
  doFetch(`/api/project/${projectId}/invite/${inviteId}/`, "PUT", { status }).then(() => {
    if (triggerInvites) triggerInvites();
    if (triggerMembers) triggerMembers();
  });
};

const deleteInvite = ({ projectId, item, triggerInvites }) => {
  let inviteId = item.get("uid");
  doFetch(`/api/project/${projectId}/invite/${inviteId}/`, "DELETE").then(() => {
    if (triggerInvites) triggerInvites();
  });
};

const getColumns = (props) => [
  {
    name: "Email",
    sortable: true,
    selector: (item) => item.get("email"),
    format: (item) => item.get("email"),
    width: "45%",
  },
  {
    name: "Role",
    sortable: true,
    selector: (item) => item.getIn(["project_discipline", "name"]),
    format: (item) => item.getIn(["project_discipline", "name"]),
    width: "45%",
  },
  {
    name: "",
    right: true,
    selector: (item) => "",
    format: (item) => (
      <Hide when={!props?.isAdmin}>
        <ActionIcon
          iconName="check"
          onClick={() => repsondInvite({ ...props, item, accept: true })}
          addClass={"mr-2"}
        />
        <ActionIcon iconName="trashcan" onClick={() => deleteInvite({ ...props, item })} />
      </Hide>
    ),
    width: "10%",
  },
];

export default function InviteList({ projectId }) {
  let { isAdmin } = useProjectPermissions();
  let { trigger: triggerMembers } = useApi("/api/project/{projectId}/member/", { projectId });
  let { data: invites, trigger: triggerInvites } = useApi("/api/project/{projectId}/invite/", { projectId });

  if (!invites?.size) return null;

  return (
    <div className="row">
      <BSCard
        widthOffset="col-sm-12"
        title="Project Invites"
        body={
          <DataTable
            columns={getColumns({ projectId, triggerMembers, triggerInvites, isAdmin })}
            data={invites?.toArray()}
            striped={true}
            dense={true}
          />
        }
      />
    </div>
  );
}
