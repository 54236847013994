import React, { useEffect } from "react";
import PendingSupplierRequests from "AppSrc/project/details/supplierRequest";
import { useFullIds } from "../utils/hooks";
import { Spinner } from "traec-react/utils/entities";

import { BreadCrumb } from "traec-react/project/utils";

import { ErrorBoundary } from "traec-react/errors";
import useApi from "storybook-dashboard/utils/fetching";
import { ProjectPermission } from "../utils/permissions";

import MemberList from "./memberList";
import InviteList from "./inviteList";
import DisciplineList from "./disciplineList";
import AuthGroupList from "./authGroupList";

import useProjectContext from "../context";

export function ProjectMembers({ projectId }) {
  // const context = useProjectContext();

  return (
    <>
      <h3>Project Member Admin</h3>
      {/* <BreadCrumb company={company} project={project} /> */}

      {/*Render the members panel if allowed */}

      <ErrorBoundary>
        <MemberList projectId={projectId} />
      </ErrorBoundary>

      <ErrorBoundary>
        <InviteList projectId={projectId} />
      </ErrorBoundary>

      <ErrorBoundary>
        <DisciplineList projectId={projectId} />
      </ErrorBoundary>

      <ErrorBoundary>
        <ProjectPermission projectId={projectId} requiresAdmin={true}>
          <AuthGroupList projectId={projectId} />
        </ProjectPermission>
      </ErrorBoundary>
    </>
  );
}

export default function ProjectMemberPage(props) {
  let { projectId } = useFullIds();
  let { data: project, isLoading } = useApi("/api/project/{projectId}/", { projectId });

  if (isLoading || !project) return <Spinner />;

  return (
    <React.Fragment>
      <ProjectMembers projectId={projectId} />
      <PendingSupplierRequests project={project} />
    </React.Fragment>
  );
}
