import React, { useState } from "react";
import ReportRowErrorBoundary from "../error";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors";
import { validateFields } from "./validateFields";
import Im from "immutable";
import Octicon from "react-octicon";

export const TableRow = (props) => {
  let { fields, data, copyHandler, updateHandler, removeHandler, readOnly, setEditingForm } = props;
  let [editingRow, setEditingRow] = useState(false);
  let [_data, setData] = useState(data);

  let cells = fields.map((field, i) => <td key={i}>{data.get(field.get("header"))}</td>);

  if (editingRow) {
    return (
      <AddRowForm
        fields={fields}
        values={_data}
        editingRow={editingRow}
        addHandler={(e) => {
          updateHandler(_data);
          setEditingRow(false);
          setEditingForm(false);
        }}
        onChangeHandler={(e) => {
          setData(_data.merge({ [e.target.name]: e.target.value }));
        }}
      />
    );
  }

  return (
    <ReportRowErrorBoundary>
      <tr>
        {readOnly ? null : (
          <td className="text-center">
            <BSBtnDropdown
              header={" "}
              floatStyle={" "}
              links={[
                { name: "Copy", onClick: (e) => copyHandler(data) },
                {
                  name: "Edit",
                  onClick: (e) => {
                    setData(data);
                    setEditingRow(true);
                    setEditingForm(true);
                  },
                },
                { name: "Delete", onClick: removeHandler },
              ]}
            />
          </td>
        )}
        {cells}
      </tr>
    </ReportRowErrorBoundary>
  );
};

export const AddRowForm = (props) => {
  let { fields, values, addHandler, onChangeHandler, editingRow } = props;
  let [errors, setErrors] = useState(Im.Map());

  if (!fields) {
    return null;
  }

  let inputs = fields.map((field, i) => (
    <FormCellInput key={i} field={field} values={values} onChangeHandler={onChangeHandler} errors={errors} />
  ));

  let errorMessages = errors.keySeq().map((header, i) => (
    <p key={i} className="text-danger m-0 p-0">
      <b>{header}</b>: {errors.get(header)}
    </p>
  ));

  return (
    <ErrorBoundary>
      <tr style={{ marginTop: "1px solid black" }}>
        <td className="text-center">
          <a
            onClick={(e) => {
              let _errors = validateFields(fields, values);
              if (!_errors.size) {
                addHandler(e);
                setErrors(Im.Map());
              } else {
                setErrors(_errors);
              }
            }}
            style={{ cursor: "pointer" }}
            className="btn btn-sm btn-primary text-white my-1 py-0 text-nowrap"
          >
            {!editingRow ? (
              <>
                <Octicon name="plus" /> Add
              </>
            ) : (
              <>
                <Octicon name="check" /> Done editing
              </>
            )}
          </a>
        </td>
        {inputs}
      </tr>
      {errors.size ? (
        <tr>
          <td colSpan={100}>{errorMessages}</td>
        </tr>
      ) : null}
    </ErrorBoundary>
  );
};

const FormCellInput = (props) => {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let fieldType = field.get("type");
  let error = errors.get(header);
  let value = values.get(header);

  if (fieldType === "selection") {
    return <FormCellDropdown {...props} />;
  }

  return (
    <td className="border-0">
      {fieldType === "textarea" ? (
        <textarea
          rows={"3"}
          type="text"
          className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
          id={header}
          name={header}
          value={value}
          onChange={onChangeHandler}
        />
      ) : (
        <input
          type="text"
          className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
          id={header}
          name={header}
          value={value}
          onChange={onChangeHandler}
        />
      )}
    </td>
  );
};

const FormCellDropdown = (props) => {
  let { field, values, errors, onChangeHandler } = props;
  let header = field.get("header");
  let error = errors.get(header);
  let value = values.get(header);

  // If the options is a dictionary then options depends on other fields
  let options = field.get("options") || Im.List();
  if (Im.Map.isMap(options)) {
    let dependentFields = Im.List(options.keys());
    let optionSet = dependentFields.reduce((a, dependentField) => {
      let dependentValue = values.get(dependentField);
      return a.union(options?.get(dependentField)?.get(dependentValue) || Im.List());
    }, Im.Set());
    options = Im.List(optionSet);
  }

  let _options = options.unshift(null).map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ));

  return (
    <td>
      <select
        type="text"
        className={`form-control form-control-sm ${error ? "is-invalid" : ""}`}
        id={header}
        name={header}
        value={value}
        onChange={onChangeHandler}
      >
        {_options}
      </select>
    </td>
  );
};
