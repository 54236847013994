import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import ProjectHome from "./home";
import ProjectMetrics from "./metrics";
import ProjectTemplateMetrics from "AppSrc/project/metrics/template";
import WPCommits from "./commits";
import ConvFactorTable from "./convfacts";
import ProjectReport from "./report";
import ProjectDetails from "AppSrc/project/details";
import ProjectIndicators from "./indicators/";
import WorkPackageDetails from "./wptree/details";

import ProjectExportPage from "storybook-dashboard/dashboard/export/project/page";
import useToken from "storybook-dashboard/auth/token";

import ProjectIndicatorTarget from "AppSrc/project/indicators/indicatorTargets";
import ProjectApportionment from "AppSrc/project/apportionment";
import ProjectMembersPage from "AppSrc/project/members";
import EmailSettings from "traec-react/emails/settings";
import EmailReport from "traec-react/emails/report";
import RecipientEmailReport from "traec-react/emails/report/recipientEmailReport";

import { ErrorBoundary } from "traec-react/errors";

export function ProjectRouter(props) {
  return (
    <ErrorBoundary>
      <Switch>
        {/* there will only ever be one child in the Switch */}

        {/* Pages for Members, Invites and Auth */}
        <Route exact path={`/project/:_projectId/members`} component={ProjectMembersPage} />

        {/* Pages the Project Details */}
        <Route exact path={`/project/:_projectId/details`} component={ProjectDetails} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/details`} component={WorkPackageDetails} />

        {/* Pages for Members, Invites and Auth */}
        <Route exact path={`/project/:_projectId/metrics`} component={ProjectMetrics} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/metrics`} component={ProjectMetrics} />
        <Route
          exact
          path={`/project/:_projectId/wpack/:_refId/metrics/add_from_template`}
          component={ProjectTemplateMetrics}
        />
        <Route exact path={`/project/:_projectId/metrics/add_from_template`} component={ProjectTemplateMetrics} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/metrics/:_commitId`} component={ProjectMetrics} />

        {/* Pages for dashboard exports */}
        <Route exact path={`/project/:_projectId/export/`} component={ProjectExportPage} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/export/`} component={ProjectExportPage} />

        {/* Pages for Performance Evaluations (commits) */}
        <Route exact path={`/project/:_projectId/evals`} component={WPCommits} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/evals`} component={WPCommits} />

        {/* Pages for Performance Evaluations (commits) */}
        <Route exact path={`/project/:_projectId/indicators`} component={ProjectIndicators} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/indicators`} component={ProjectIndicators} />

        {/* Repot page for generating pdf indicator/metric report  */}
        {/* <Route exact path={`/project/:_projectId/report`} component={ProjectReport} /> */}
        {/* Reporting input form for a particular ref/commit */}
        <Route exact path={`/project/:_projectId/wpack/:_refId/report`} component={ProjectReport} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/report/:_commitId`} component={ProjectReport} />

        {/* Pages for Conversion Factors and Indicators */}
        <Route exact path={`/project/:_projectId/indicators`} component={ProjectIndicators} />
        <Route exact path={`/project/:_projectId/conv`} component={ConvFactorTable} />
        <Route exact path={`/project/:_projectId/indicator/:indicatorId/target`} component={ProjectIndicatorTarget} />
        <Route exact path={`/project/:_projectId/wpack/:_refId/conv`} component={ConvFactorTable} />
        <Route
          exact
          path={`/project/:_projectId/wpack/:_refId/indicator/:indicatorId/target`}
          component={ProjectIndicatorTarget}
        />
        <Route exact path={`/project/:_projectId/wpack/:_refId/apportionment`} component={ProjectApportionment} />
        <Route
          exact
          path={`/project/:_projectId/wpack/:_refId/apportionment/:reportingPeriodId`}
          component={ProjectApportionment}
        />

        {/* Pages for email statistics */}
        <Route exact path={`/project/:_projectId/email/settings`} component={EmailSettings} />
        <Route exact path={`/project/:_projectId/email/report`} component={EmailReport} />
        <Route exact path={`/project/:_projectId/email/report/:recipientId`} component={RecipientEmailReport} />

        {/* Default render homepage if no path matched */}
        <Route exact path={`/project/:_projectId/wpack/:_refId`} component={ProjectHome} />
        <Route component={ProjectHome} />
      </Switch>
    </ErrorBoundary>
  );
}
