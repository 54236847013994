import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { AddRowForm, TableRow } from "./tableRow";
import { TableHeaders } from "./headers";
import { HeaderButtons } from "./headerButtons";
import Im from "immutable";

export const DataTable = (props) => {
  let { fields, formName, data, setData, readOnly } = props;

  let initNewData = fields.reduce((acc, cur) => acc.set(cur.get("header"), ""), Im.Map());
  let [newData, setNewData] = useState(initNewData);
  let [editingForm, setEditingForm] = useState(false);

  let filename = `${formName}.csv`;

  let rows = data.map((d, i) => (
    <TableRow
      key={i}
      index={i}
      fields={fields}
      data={d}
      setData={setData}
      readOnly={readOnly}
      removeHandler={(e) => {
        confirmDelete({
          text: `Are you sure you want to remove this row?`,
          onConfirm: () => {
            setData(data.delete(i));
          },
        });
      }}
      updateHandler={(rowData) => {
        setData(data.set(i, rowData));
      }}
      copyHandler={(rowData) => {
        setData(data.insert(i, rowData));
      }}
      setEditingForm={setEditingForm}
    />
  ));

  return (
    <ErrorBoundary>
      <HeaderButtons {...props} filename={filename} editingForm={editingForm} />
      <div style={{ clear: "both" }} />

      <div className="scrollTopContainer m-0 p-0" style={{ overflowX: "auto" }}>
        <table width="100%" className="table table-sm scrollTopContent m-0 p-0">
          <thead>
            <TableHeaders fields={fields} readOnly={readOnly} />
          </thead>
          <tbody>
            {readOnly || editingForm ? null : (
              <AddRowForm
                fields={fields}
                values={newData}
                onChangeHandler={(e) => {
                  setNewData(newData.merge({ [e.target.name]: e.target.value }));
                }}
                addHandler={(e) => {
                  setData(data.unshift(newData));
                  setNewData(initNewData);
                }}
              />
            )}
            {rows}
          </tbody>
        </table>
      </div>
    </ErrorBoundary>
  );
};
